<template>
    <v-container>
        <v-btn class="mb-4" @click="edit(null)">
            Neu <v-icon right small>fas fa-plus</v-icon>
        </v-btn>
        <v-expansion-panels v-model="selected" tile mandatory focusable>
            <v-expansion-panel v-if="editItem != null" :disabled="editLoading">
                <v-expansion-panel-header>
                    {{editItem.ID == null ? 'Erstellen' : `[${editItem.ID}] '${editItem.title}' bearbeiten`}}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-form v-model="editValid">
                        <v-container>
                            <v-row>
                                <v-col>
                                    <v-text-field v-model="editItem.title" :rules="rule_notempty" label="Titel" required></v-text-field>
                                    <v-text-field v-model="editItem.price" :rules="rule_notempty" label="Einzelpreis" type="number" required></v-text-field>
                                    <axz-file-picker v-model="editItem.image" :rules="file_rules" label="Bild" accept="image/*" required></axz-file-picker>
                                </v-col>
                            </v-row>
                            <v-row justify="end" class="mt-4">
                                <v-btn v-if="editItem.ID == null" @click="create()" :loading="editLoading" :disabled="!editValid">
                                    Anlegen <v-icon right small>fas fa-save</v-icon>
                                </v-btn>
                                <v-btn v-else @click="update()" :loading="editLoading" :disabled="!editValid">
                                    Speichern <v-icon right small>fas fa-save</v-icon>
                                </v-btn>
                            </v-row>
                            
                        </v-container>
                    </v-form>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    Shop Artikel
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-data-table :headers="cols" :items="$db.shop.items" item-key="ID" :items-per-page="-1" dense :hide-default-footer="true" :loading="$db.loading">
                        <template v-slot:item.actions="{ item }">
                            <v-icon small class="ml-2" @click="edit(item)">fas fa-pen</v-icon>
                        </template>
                    </v-data-table>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-row>
            <v-col>
                <v-card tile class="pb-4">
                    <v-card-title>
                        Alle Bestellungen
                    </v-card-title>
                    <!-- @click:row="(val, item) => { item.expand(!item.isExpanded) }" -->
                    <v-data-table :headers="cols_orders" :items="$db.shop.orders" item-key="ID" :items-per-page="-1" group-by="statusid" dense :hide-default-footer="true" show-expand single-expand>
                        <template v-slot:item.ID="{ item }" >
                            <span :class="item.status == 'cancelled' ? 'text-decoration-line-through red--text' : ''">#AXZ{{new Date(item.created).getTime()+(new Array(4).join('0')+item.ID).slice(-4)}}</span>
                        </template>
                        <template v-slot:item.username="{ item }">
                            <v-avatar size="32" left class="mr-2">
                                <axz-img :id="item.userimage" dummy="trikot_aXz.png" />
                            </v-avatar>
                            [{{item.userid}}] {{item.username}}
                        </template>
                        <template v-slot:item.created="{ item }">
                            {{UTCtoLocal(item.created)}}
                        </template>
                        <template v-slot:item.total="{ item }">
                            {{item.total}}€
                        </template>
                        <template v-slot:item.status="{ item }">
                            <v-select v-if="item.statusid != 6" v-model="item.statusid" :items="$db.shop.status" item-text="name" item-value="ID" @change="changeStatus(item)">
                                <template v-slot:item="{ item }">
                                    {{statusDE[item.name]}}
                                </template>
                                <template v-slot:selection="{ item }">
                                    {{statusDE[item.name]}}
                                </template>
                            </v-select>
                            <span v-else>{{statusDE[item.status]}}</span>
                        </template>
                        <!--template v-slot:item.actions="{ item }">
                            <v-icon small class="ml-2" @click="cancelOrder(item)" :disabled="!(item.status == 'placed' || item.status == 'payed')">fas fa-ban</v-icon>
                        </template-->
                        <template v-slot:group.header="{ headers, group, items }">
                            <td :colspan="headers.length" class="text-caption text-uppercase pl-2">{{statusDE[items[0].status]}} / {{items[0].status}}</td>
                        </template>
                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length" class="px-6 pb-2">
                            <v-data-table :headers="cols_order_items" :items="item.items" item-key="item" :items-per-page="-1" dense :hide-default-footer="true">
                                <template v-slot:item.title="{ item }">
                                {{item.title}} {{item.variant}}
                                </template>
                                <template v-slot:item.price="{ item }">
                                {{item.price}}€
                                </template>
                                <template v-slot:item.total="{ item }">
                                {{item.total}}€
                                </template>
                            </v-data-table>
                            </td>
                        </template>
                        <!--template v-slot:footer>
                            <v-row justify="center">
                                <v-col class="text-center"><v-icon small left>fas fa-eye</v-icon> = Online</v-col>
                                <v-col class="text-center"><v-icon small left color="grey">fas fa-eye-slash</v-icon> = Offline</v-col>
                                <v-col class="text-center"><v-icon small left color="grey">fas fa-lock</v-icon> = Intern</v-col>
                                <v-col class="text-center"><v-icon small left>fas fa-unlock</v-icon> = Extern</v-col>
                            </v-row>
                        </template-->
                    </v-data-table>
                    </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data () {
        return {
            loading: false,
            cols: [
                { text: 'ID', value: 'ID' },
                { text: 'Überschrift', value: 'title' },
                { text: 'Einzelpreis', value: 'price' },
                { text: 'Aktionen', value: 'actions', align: 'end', sortable: false },
            ],
            statusDE: {
                placed: 'aufgegeben',
                payed: 'bezahlt',
                ordered: 'bestellt',
                shipped: 'versandt',
                delivered: 'geliefert',
                cancelled: 'storniert'
            },
            cols_orders: [
                { text: '# Bestellnummer', value: 'ID' },
                { text: 'Benutzer', value: 'username' },
                { text: 'Erstellt am', value: 'created' },
                { text: 'Artikel', value: 'totalcount' },
                { text: 'Preis', value: 'total' },
                { text: 'Status', value: 'status' },
                //{ text: 'Aktionen', value: 'actions', align: 'end', sortable: false },
                { text: '', value: 'data-table-expand' },
            ],
            cols_order_items: [
                { text: 'Artikel', value: 'title', align: 'start', sortable: false},
                { text: 'Zusatz', value: 'info', align: 'start', sortable: false},
                { text: 'Einzelpreis', value: 'price', align: 'end' },
                { text: 'Anzahl', value: 'count', align: 'end' },
                { text: 'Preis', value: 'total', align: 'end' },
            ],
            selected: 0,
            editItem: null,
            editValid: false,
            editLoading: false,
            file_rules: [
                value => !!value && value.size < 20000000 || 'File size should be less than 20 MB!',
            ],
            rule_notempty: [
                v => !!v || 'required',
            ],
        }
    },
    computed: {

    },
    watch: {
        selected () {
            if(this.selected != 1 && this.editItem != null){
                this.editItem = null;
                this.$db.shop.loadItems(); //or clone edit item !=??!?!?
            }
        },
    },
    methods: {
        edit(item) {
            if(item != null) {
                this.editItem = Object.assign(item);
            }
            else {
                this.editItem = {
                    ID: null,
                    title: null,
                    price: null,
                    image: null,
                }
            }
            this.selected = 1;
        },
        async create(){
            this.editLoading = true;
            this.$db.shop.createItem(
                this.editItem.title, 
                this.editItem.price, 
                await this.$db.files.create(this.editItem.image)
            ).then(() => {
                this.editLoading = false;
                this.editItem = null;
                this.$db.shop.loadItems();
            });
        },
        async update(){
            this.editLoading = true;
            this.$db.shop.updateItem(
                this.editItem.ID,
                this.editItem.title, 
                this.editItem.price, 
                typeof this.editItem.image == 'object' ? await this.$db.files.createOrUpdate(this.editItem.image.identifier, this.editItem.image) : this.editItem.image,
            ).then(() => {
                this.editLoading = false;
                this.editItem = null;
                this.$db.shop.loadItems();
            });
        },
        changeStatus(item){
            this.$db.shop.changeStatus(item.ID, item.statusid).then(() => {
                this.$db.shop.loadOrders();
            });
        }
    },
    mounted() {
        this.$db.shop.loadItems();
        this.$db.shop.loadStatus();
        this.$db.shop.loadOrders();
    }
}
</script>